.verify-product {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0px;
    background: #f2f2f2;}

.product-box {width: 60%;}
.product-box h3 {
    margin: 6px;
    color: #000;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;}
.mb-10{ margin-bottom: 10px;}
.card-box {background-color: #f2f2f2 !important;
    box-shadow: none !important;}
.inner-card { padding: 10px;}
.product-detail-lst {padding-left: 0px;}
.product-detail-lst li { list-style: none;
    list-style: none;
    text-align: left;
    margin-bottom: 9px; display: flex;}
.scan-status {text-align: left;
    margin: 14px 0px;
    font-size: 18px;
    color: #ff0000;
    line-height: 20px; display: flex;}
.scan-status .MuiSvgIcon-root{ margin-right: 9px !important;}
.product-detail-lst li .MuiSvgIcon-root{
    margin-right: 9px !important;
    font-size: 18px !important;
    color: #000 !important;}
.scan-alert { border: 1px solid #d3d3d3;
    padding: 10px;
    text-align: center;
    width: 500px;
    padding: 30px 0px;}
    .scan-alert strong { font-weight: 600; margin-bottom: 10px; display: block; font-size: 20px; color: #000;}
    .scan-alert .stack-button-group { justify-content: center !important;}
.ques-button { justify-content: center !important;}
.mb-20 { margin: 20px 0px;}
.mb-auto{ margin-top:15px !important;}
.video-title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 500;
    margin-bottom: 8px;}
.learn { text-align: left;}
.sec-title { text-transform: capitalize; text-align: left;}
.learn p{ text-align: left;}
.connect .inputs{ width:100% !important}
@media only screen and (max-width: 768px) {
    .scan-alert { width: auto !important;}
    .product-box {
        width: 90% !important;}
        .button-group .stacks-button-group{ display: block !important;}
        .button-group button{ margin-bottom: 10px !important;}
        .header {
            position: fixed;
            z-index: 99;
            padding: 0px 16px !important;
        }
        .header img {
            width: 93px;
        }
        .carousel-caption { left: 5%;}
        .carousel-caption h3 {
            font-size: 18px;}
            .carousel-control-next, .carousel-control-prev{ display: none !important;}
    .carousel-caption p { margin-bottom: 5px !important;}
}

.button-group .stacks-button-group{
    justify-content: center !important; margin-bottom: 20px;}
    .button-group button {
        border: 1px solid #333 !important; color: #333;}
        .button-group a { text-decoration: none;}
        .genuine {color:#2e7d32 !important;}

        .scantitle {
            text-align: left;
            color: #000;
            font-size: 18px;
            margin-bottom: 10px;}
.scanhistorylist {
    padding-left: 0px;
    display: flex !important;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    margin: 6px 0px;}

.scanhistorylist li .MuiSvgIcon-root {
    margin-right: 9px !important;
    font-size: 18px !important;
    color: rgba(0, 0, 0, 0.6)!important;
}
.helplinetitle {
    color: #000;
    font-size: 16px;
    margin-bottom: 0px;
    text-align: center;}
.helplinetitle a {
    display: block;
    font-size: 28px;
    text-decoration: none;
    margin-top: 5px;
    line-height: 25px;
    color: #0072E5;
    display: flex;
    justify-content: center;}
.formsubmit .inputs {width: 100% !important;}
.scanhistorytable{
    width: 100%;
    border: 1px solid #d3d3d3;}
.scanhistorytable th { border-bottom: 1px solid #d3d3d3; color: #000; background: #f2f2f2; text-align: left; padding: 0px 20px; height: 30px;}
.scanhistorytable td { border-bottom: 1px solid #d3d3d3; height: 30px; text-align: left; padding: 0px 20px; }

.css-serial {
    counter-reset: serial-number;  /* Set the serial number counter to 0 */
  }
  
  .css-serial td:first-child:before {
    counter-increment: serial-number;  /* Increment the serial number counter */
    content: counter(serial-number);  /* Display the counter */
  }
  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #000 !important;
    text-align: left !important;
    top: 30%;
}
.appimg { margin-top: 20px;}
.carousel-indicators [data-bs-target]{
    background-color: #000 !important;
}

.header {
    position: fixed;
    z-index: 99;
    padding: 10px 50px;}

    .small{
        color:red;
      }
      .large{
        color:blue;
      }
.helplinetitle2 {
    text-align: left;}
.helplinetitle2 a{
    justify-content: left;}
.scan-alert2 {
        width: auto;
        padding: 19px;
        margin-top: 15px;}
.scan-alert2 strong {
            font-size: 18px;}
.formsubmitt {
    border: 1px solid #d3d3d3;
    margin: 10px;
    padding: 10px;}
.qrcodeview li{ 
    justify-content: center;
    font-size: 16px;}
